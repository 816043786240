import React from "react";
import OttIcon from "../Assets/ott-icon.png";

const PlansTable = ({
  fibernetTableHeaders,
  ottTableHeaders,
  comoboTableHeaders,
  activeTab,
  fibernetPlansData,
  ottPlansData,
  comboPlansData,
  onSelectFibernetPlan,
  onSelectOttPlan,
  onSelectComboPlan,
}) => {
  const getHeaders = () => {
    if (activeTab === 1) return fibernetTableHeaders;
    if (activeTab === 2) return ottTableHeaders;
    return comoboTableHeaders;
  };

  const getData = () => {
    if (activeTab === 1) return fibernetPlansData;
    if (activeTab === 2) return ottPlansData;
    return comboPlansData;
  };

  return (
    <div
      style={{
        height: "450px",
        overflowY: "scroll",
        borderBottom: "1px solid rgba(189, 189, 189, 1)",
      }}
    >
      <table
        style={{
          marginTop: "20px",
          fontSize: "14px",
          color: " rgba(0, 0, 0, 1)",
          width: "100%",
        }}
      >
        <thead
          style={{
            borderBottom: "1px solid rgba(189, 189, 189, 1)",
          }}
        >
          <tr>
            <th style={{ width: "6%" }} />
            {getHeaders().map((header) => (
              <th
                style={{
                  width: "20%",
                  fontWeight: 400,
                  height: "45px",
                }}
                key={header}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getTableBody(
            activeTab,
            getData(),
            onSelectFibernetPlan,
            onSelectOttPlan,
            onSelectComboPlan
          )}
        </tbody>
      </table>
    </div>
  );
};

const getTableBody = (
  type,
  data,
  onSelectFibernetPlan,
  onSelectOttPlan,
  onSelectComboPlan
) => {
  switch (type) {
    case 1:
      return data.map((row) => (
        <tr key={row.id} style={{ height: "40px" }}>
          <td style={{ width: "6%" }}>
            <input
              type="radio"
              checked={!!row.isSelected}
              style={{ marginLeft: "40px" }}
              onChange={() => onSelectFibernetPlan(row)}
            />
          </td>
          <td style={{ width: "24%", textAlign: "center" }}>
            {row.package_name}
          </td>
          <td style={{ width: "24%", textAlign: "center" }}>
            {row.total_plan_cost}
          </td>
          <td style={{ width: "24%", textAlign: "center" }}>{row.time_unit}</td>
          <td style={{ width: "24%", textAlign: "center" }}>
            {row.download_speed}
          </td>
        </tr>
      ));
    case 2:
      return data.map((row) => (
        <tr key={row.id} style={{ height: "40px" }}>
          <td style={{ width: "6%" }}>
            <input
              type="radio"
              checked={!!row.isSelected}
              style={{ marginLeft: "40px" }}
              onChange={() => onSelectOttPlan(row)}
            />
          </td>
          <td style={{ width: "24%", textAlign: "center" }}>{row.plan_name}</td>
          <td style={{ width: "24%", textAlign: "center" }}>{row.total}</td>
          <td style={{ width: "24%", textAlign: "center" }}>
            {row.plan_duration} month(s)
          </td>
          <td
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ objectFit: "contain", height: "35px", width: "35px" }}
              src={OttIcon}
              alt="ott-icon"
            />
            <img
              style={{
                objectFit: "contain",
                height: "35px",
                width: "35px",
                position: "absolute",
                left: "25px",
              }}
              src={OttIcon}
              alt="ott-icon"
            />
            <div style={{ marginLeft: 5 }}>+{row.ott_services.length}</div>
          </td>
        </tr>
      ));
    default:
      return data.map((row) => (
        <tr key={row.id} style={{ height: "40px" }}>
          <td style={{ width: "6%" }}>
            <input
              type="radio"
              checked={!!row.isSelected}
              style={{ marginLeft: "40px" }}
              onChange={() => onSelectComboPlan(row)}
            />
          </td>
          <td style={{ width: "20%", textAlign: "center" }}>
            {row.combo_plan_name}
          </td>
          <td style={{ width: "20%", textAlign: "center" }}>{row.total}</td>
          <td style={{ width: "20%", textAlign: "center" }}>
            {row.plan_duration} month(s)
          </td>
          <td style={{ width: "20%", textAlign: "center" }}>
            {row.internet_speed}
          </td>
          <td
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ objectFit: "contain", height: "35px", width: "35px" }}
              src={OttIcon}
              alt="ott-icon"
            />
            <img
              style={{
                objectFit: "contain",
                height: "35px",
                width: "35px",
                position: "absolute",
                left: "10px",
              }}
              src={OttIcon}
              alt="ott-icon"
            />
            <div style={{ marginLeft: 5 }}>+{row.ott_services.length}</div>
          </td>
        </tr>
      ));
  }
};

export default PlansTable;
