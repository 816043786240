// import React, { useEffect, useState } from "react";
// import SidePaneHOC from "./SidePaneHOC";
// import { UpgradePlanTable } from "./UpgradePlanTable";
// import PaymentList from "./paymentlist";
// import {
//   getUpgradePlaData,
//   getPlanPayment,
//   paymentAPIUpgrade,
//   getPriorCheck,
//   getBranchList,
//   getAMountCalculation,
// } from "../Axios";
// import { SunspotLoader } from "react-awesome-loaders";
// import { CgClose } from "react-icons/cg";
// import {
//   Modal,
//   ModalBody,
//   ModalFooter,
//   Button,
//   ModalHeader,
//   Container,
//   Input,
//   Label,
//   Row,
// } from "reactstrap";
// import { Alert } from "../CommonComponents/Alert";
// import { capitalizeString } from "../Utils/Constants";
// import moment from "moment";
// import StaticIp from "./StaticIP";
// import { useHistory } from "react-router-dom";
// import PaymentStatusSuccessModal from "./PaymentStatusSuccessmodal";
// import PaymentStatusPendingModal from "./PaymentStatusPendingModal";
// import PaymentStatusFailUpgrade from "./PaymentStatusFailupgrde";
// import ReconnectingWebSocket from "reconnecting-websocket";
// const headerStyle = {
//   backgroundColor: "rgb(63 120 233 / 100%)",
//   color: "white",
//   position: "sticky",
//   top: "0",
//   zIndex: "999",
// };
// const iconFillColor = "#3f78e9";
// const iconHeight = 25;
// const iconWidth = 25;
// const icondisplay = {
//   display: "initial",
// };
// const ChangePlan = (props) => {
//   const areaid = props.areaId;
//   // staticIpBind
//   const staticIpBind = props.static_ip_bind;
//   const staticCost = props.static_ip_cost;
//   const ipPoolId = props.ippool;
//   const radiusInfoId = props.radiusId;
//   const planTimtUnit = props.plan_time_unit;
//   const totalStatic = props.static_ip_total_cost;
//   const staticCgst = props.static_ip_cgst;
//   const statisSgst = props.static_ip_sgst;
//   const walletAmount = props.wallet_info;
//   const [paymentStatus, setPaymentStatus] = useState(false);
//   const paymentStatusModal = () => {
//     setPaymentStatus(!paymentStatus);
//   };

//   const [paymentPending, setPyamentpending] = useState(false);
//   const paymentPendingModal = () => {
//     setPyamentpending(!paymentPending);
//   };
//   const [paymentFail, setPyamentFail] = useState(false);
//   const paymentFailModal = () => {
//     setPyamentFail(!paymentPending);
//   };
//   // spinner
//   const [loaderSpinneer, setLoaderSpinner] = useState(false);
//   const { customerId } = props;
//   const { onClose, allOrPartialPlans, setAllOrPartialPlansFetch } = props;
//   const [upgradePlansData, setUpgradePlansData] = useState([]);
//   const [filterPlan, setFIlterPlan] = useState([]);
//   const [selectedPlan, setSelectedPlan] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [showAllPartial, setShowAllPartial] = useState(true);
//   const [showpayment, setShowPayment] = useState(false);
//   const [selectedPaymentId, setSelectedPaymentId] = useState(null);
//   const [sucModal, setSucModal] = useState(false);
//   const [alertMessage, setAlertMessage] = useState(null);
//   const [loadingPay, setLoadingPay] = useState(false);
//   const [showPaymentLinkSentModal, setPaymentLinkSentModal] = useState({
//     message: "",
//     uiStatus: false,
//   });
//   const [isRetry, showRetry] = useState(false);
//   const [changeplan, setChangeplan] = useState({});

//   // wallet amount
//   const [walletamountcheckbox, setWalletamountcheckbox] = useState(false);
//   //handle click for check box
//   const handleClick = (e) => {
//     setWalletamountcheckbox(e.target.checked);
//   };
//   // toggle show for alreday static ip
//   const [staticipToggle, setStaicIpToggle] = useState("on");
//   const [staticshow, setStaticShow] = useState(staticIpBind ? true : false);
//   function showStaticipToggle() {
//     setStaicIpToggle(staticipToggle === "on" ? "off" : "on");
//     setStaticShow(!staticshow);
//   }
//   // pool list
//   const [ipPool, setIpPool] = useState([]);
//   // static ip's and plan cost
//   const [staticIP, setStaticIP] = useState([]);
//   const [staticIPCost, setStaticIPCost] = useState({});

//   const history = useHistory();

//   const handleClose = () => {
//     onClose();
//   };
//   const handleSelectedPlanChange = (e, rowData) => {
//     const selected = e.target.value;
//     const selectedPlan = upgradePlansData.find((item) => item.id === selected);
//     setSelectedPlan(rowData);
//   };

//   const handleSeeAllPlansClick = () => {
//     setAllOrPartialPlansFetch("all");
//   };

//   const handleGreaterThanPlansClick = () => {
//     setAllOrPartialPlansFetch("partial");
//   };

//   const handleChange = (e) => {
//     setChangeplan((prev) => ({
//       ...prev,
//       [e.target.name]: e.target.value,
//     }));
//     if (e.target.name == "ippool") {
//       getStaticIP(e.target.value);
//     }
//   };
//   useEffect(() => {
//     const id = props.planId;
//     const areaid = props.areaId;

//     (async function () {
//       try {
//         let response = null;
//         if (allOrPartialPlans === "all") {
//           response = await getUpgradePlaData(
//             `/accounts/loggedin/${areaid}/plans/${id}`
//           );
//         } else {
//           response = await getUpgradePlaData(
//             `/accounts/area/${areaid}/plans/${customerId}`
//           );
//         }
//         const { data } = response;
//         allOrPartialPlans === "all"
//           ? setShowAllPartial(false)
//           : setShowAllPartial(true);
//         setUpgradePlansData(data);
//         setFIlterPlan(response.data);
//         setLoading(false);
//       } catch (e) {
//         console.log(e);
//       }
//     })();
//   }, [allOrPartialPlans]);

//   const submitdata = () => {
//     if (!!selectedPaymentId) {
//       setSucModal(true);
//       setAlertMessage("Your payment is processing...");
//       setLoadingPay(true);
//       const obj = {
//         amount: selectedPlan.total_plan_cost,
//         gst_calculated: "true",
//         source: "IP",
//         gateway_id: selectedPaymentId,
//       };
//       getPlanPayment("payment/", obj).then((response) => {
//         console.log(response.data, "payment");
//         setUpgradePlansData({});

//         listenToWebsocket(response.data.payment_id);
//       });
//     } else {
//       console.log("errors try again");
//     }
//   };

//   const listenToWebsocket = (payment_id) => {
//     let billingbaseurl =
//       process.env.REACT_APP_API_URL_PAYMENT_PLAN.split("//")[1];
//     let protocol = window.location.protocol ? "wss:" : "ws:";
//     var ws = new ReconnectingWebSocket(
//       `${protocol}//${billingbaseurl}/ws/${payment_id}/listen/payment/status`
//     );
//     ws.onopen = () => {
//       console.log("socket cnxn successful");
//     };
//     ws.onclose = (e) => {
//       console.log("socket closed", e);
//     };
//     ws.onmessage = (e) => {
//       console.log(e.data);
//       let responseData = JSON.parse(e.data);
//       if (responseData.status == 1) {
//         ws.close();
//         setLoaderSpinner(false);
//         setLoadingPay(false);
//         paymentStatusModal(true);
//       } else if (responseData.status == 2) {
//         paymentPendingModal(true);
//         setLoaderSpinner(true);
//       } else if (responseData.status == 3) {
//         paymentFailModal(true);
//         setLoaderSpinner(true);
//       } else {
//         handleClose("invoice");
//         showRetry(true);
//       }
//     };
//   };

//   const successModal = () => {
//     if (sucModal) {
//       setLoadingPay(false);
//     }
//     setSucModal(!sucModal);
//   };

//   // duew date

//   const getcalculatedduedate = () => {
//     const { startDate, time_unit, unit_type } = selectedPlan;
//     let addUnitType = "days";
//     switch (unit_type) {
//       case "mon":
//         addUnitType = "M";
//         break;
//       case "week":
//         addUnitType = "W";
//         break;
//       case "day":
//         addUnitType = "d";
//         break;
//       case "hour":
//         addUnitType = "h";
//         break;
//       case "min":
//         addUnitType = "m";
//         break;
//       default: {
//         addUnitType = "h";
//       }
//     }

//     const new_date = moment(startDate)
//       .add(time_unit, addUnitType)
//       .format("DD MMM YYYY,h:mm a");
//     return new_date;
//   };

//   // checking ippool asigning or not
//   const hideandSHowIPool = staticshow
//     ? {
//         plan: selectedPlan.id,
//         area: areaid,
//         ippool: Number(changeplan?.ippool)
//           ? Number(changeplan?.ippool)
//           : Number(ipPoolId),
//       }
//     : {
//         plan: selectedPlan.id,
//         area: areaid,
//       };
//   // calculating cost
//   const totalStaticCost = parseFloat(
//     parseFloat(
//       staticIPCost?.cost_per_ip * selectedPlan.time_unit
//         ? staticIPCost?.cost_per_ip * selectedPlan.time_unit
//         : 0
//     )
//   );
//   const TotalGST =
//     staticshow === true && selectedPlan?.plan_cgst === 0
//       ? selectedPlan.total_plan_cost * 0.18
//       : 0;
//   const checkGST = staticIpBind && TotalGST;
//   const staticipcost = staticshow
//     ? (totalStatic / planTimtUnit) * selectedPlan.time_unit
//     : null;
//   const totalAmount =
//     Number(totalStaticCost) +
//     Number(selectedPlan.total_plan_cost) +
//     Number(staticipcost) +
//     Number(checkGST);

//   // wallet priorcheck api call
//   const PaymentUpgardeSubmit = () => {
//     setLoaderSpinner(true);
//     (async function (type) {
//       try {
//         const data = {
//           plan: selectedPlan.id,
//           area: areaid,
//         };
//         const objwithPool = hideandSHowIPool;
//         const response = await getPriorCheck(
//           `wallet/priorcheck`,
//           Number(changeplan?.ippool) || ipPoolId ? objwithPool : data
//         );
//         if (response.data.check == true) {
//           setLoaderSpinner(true);
//           PaymentUpgardeplanSubmit();
//         }
//         if (response.data.check == false) {
//           setLoaderSpinner(false);
//         }
//       } catch (e) {
//         console.log(e);
//       }
//     })();
//   };

//   const withOutStatic = selectedPlan.time_unit * 212;

//   // Radius Info
//   const hideandSHowstaticIP = staticshow
//     ? {
//         id: radiusInfoId ? radiusInfoId : null,
//         static_ip_bind: changeplan.static_ip_bind
//           ? changeplan.static_ip_bind
//           : staticIpBind,
//         ippool_id: changeplan.ippool ? changeplan.ippool : ipPoolId,
//       }
//     : null;

//   // const apiRequest = () => {
//   //   // Simulate an API request with a 2-second delay
//   //   return new Promise((resolve) => setTimeout(() => resolve("Success"), 2000));
//   // };

//   // amount calcualtion

//   const [apiStatus, setApiStatus] = useState("idle");
//   const [getCalculations, setGetCalculations] = useState();
//   useEffect(() => {
//     if (apiStatus === "pending") {
//       setLoaderSpinner(true);
//     } else {
//       setLoaderSpinner(false);
//     }
//     (async function () {
//       try {
//         // apiRequest()
//         const obj = {
//           service_plan: selectedPlan.id,
//           use_wallet: walletamountcheckbox,
//         };
//         if (staticshow === true) {
//           obj.radius_info = hideandSHowstaticIP;
//         } else {
//           delete obj.radius_info;
//         }
//         console.log(obj, "obj");
//         if (selectedPlan?.id) {
//           setLoaderSpinner(true);
//           const response = await getAMountCalculation(
//             `/customers/get/update/amount/${customerId}`,
//             obj
//           );
//           const { data } = response;
//           console.log(data);
//           setGetCalculations(data);
//           setApiStatus("success");
//           setLoaderSpinner(false);
//         }
//       } catch (e) {
//         console.log(e);
//       }
//     })();
//   }, [selectedPlan, staticshow, walletamountcheckbox]);
//   // upgarde api
//   const PaymentUpgardeplanSubmit = () => {
//     showRetry(false);
//     (async function (type) {
//       try {
//         const data = {
//           ...getCalculations,
//           // plan: selectedPlan.id,
//           // amount: parseFloat(totalAmount),
//           // use_wallet: false,
//           // radius_info: hideandSHowstaticIP,
//         };
//         data.plan = selectedPlan.id;
//         delete data.balance;
//         delete data.discount_amount;
//         const response = await paymentAPIUpgrade(
//           `/customers/enh/onl/plan/update/${customerId}`,
//           data
//         );
//         if (response.data.route == true) {
//           setLoaderSpinner(true);
//           listenToWebsocket(response.data.payment_id);
//           var win = window.open(`${response.data.next}`, "_blank");
//           win && win?.focus();
//         }
//       } catch (e) {
//         console.log(e);
//         setLoaderSpinner(false);
//         setPaymentLinkSentModal({
//           message: "Something went wrong! Please try again.",
//           uiStatus: true,
//         });
//       }
//     })();
//   };

//   const totalcost = parseFloat(selectedPlan.total_plan_cost).toFixed(2);

//   // branch list for getting ippools
//   useEffect(() => {
//     (async function () {
//       try {
//         const response = await getBranchList(`network/ippool/${areaid}/get`);
//         setIpPool([...response.data]);
//       } catch (e) {
//         console.log(e);
//       }
//     })();
//   }, []);

//   // static ip
//   const getStaticIP = (val) => {
//     getBranchList(`network/ippool/used_ips/${val}`).then((res) => {
//       let { available_ips } = res.data;
//       setStaticIP([...available_ips]);
//       setStaticIPCost(res.data);
//     });
//   };

//   return (
//     <React.Fragment>
//       <div className={`relative h-full overflow-scroll`}>
//         <div
//           className="flex items-center justify-between p-4"
//           style={{ ...headerStyle }}
//         >
//           <h2 className="flex-start">
//             <a className="link" href="#" onClick={handleSeeAllPlansClick}>
//               Change Plan
//             </a>
//           </h2>
//           <CgClose
//             onClick={() => handleClose("invoice")}
//             className="yourCloseIconClass"
//             // style={{
//             //   height: iconHeight,
//             //   width: iconWidth,
//             //   cursor: "pointer",
//             // }}
//           />
//         </div>

//         {isRetry && (
//           <Alert
//             color="info"
//             onClose={() => showRetry(false)}
//             message="Something went wrong. Please"
//             anchor
//             anchorText="retry"
//             onAnchorClick={PaymentUpgardeSubmit}
//           ></Alert>
//         )}

//         <div className="m-4 p-2  upgrade-plan-table-height">
//           {!loading && (
//             <UpgradePlanTable
//               data={upgradePlansData}
//               handleSelectedPlanChange={handleSelectedPlanChange}
//               setUpgradePlansData={setUpgradePlansData}
//               filterPlan={filterPlan}
//             />
//           )}
//         </div>

//         <p className={"ml-4"}>
//           <Modal
//             isOpen={showPaymentLinkSentModal.uiStatus}
//             toggle={() =>
//               setPaymentLinkSentModal((prevState) => ({
//                 ...prevState,
//                 uiStatus: !showPaymentLinkSentModal.uiStatus,
//               }))
//             }
//             centered
//             backdrop={true}
//           >
//             <ModalBody>
//               <br />
//               <h4>{showPaymentLinkSentModal.message}</h4>
//               <br />
//             </ModalBody>
//             <br />
//             <ModalFooter style={{ textAlign: "center" }}>
//               <button
//                 className="text-sm primary-button mr-2 marquee"
//                 onClick={() => {
//                   setPaymentLinkSentModal((prevState) => ({
//                     ...prevState,
//                     uiStatus: !showPaymentLinkSentModal.uiStatus,
//                   }));
//                 }}
//               >
//                 {"Ok"}
//               </button>
//             </ModalFooter>
//           </Modal>
//           {Object.keys(selectedPlan).length > 0 && (
//             <div className="mt-10">
//               <>
//                 <h4 className="m-4">Selected Plan Details</h4>
//                 <section className="m-4">
//                   <dl className="w-full">
//                     <dt className="w-1/3">Plan Name</dt>
//                     <dd>{capitalizeString(selectedPlan.package_name)}</dd>
//                     <dt className="w-1/3">Download Speed</dt>
//                     <dd>{selectedPlan.download_speed} Mbps</dd>
//                     <dt className="w-1/3">Upload Speed</dt>
//                     <dd>{selectedPlan.upload_speed} Mbps</dd>
//                     <dt className="w-1/3">Validity</dt>
//                     <dd>
//                       {selectedPlan.time_unit +
//                         " " +
//                         selectedPlan.unit_type +
//                         "(s)"}
//                     </dd>
//                     <dt className="w-1/3">Plan Cost</dt>
//                     <dd>{totalcost}</dd>
//                     <dt className="w-1/3">Final Amount To Be Paid</dt>
//                     <dd>{getCalculations?.amount}</dd>
//                     <dt className="w-1/3">Current Expiry Date</dt>
//                     <dd>
//                       {moment(props.plan_end).format("DD MMM YYYY,h:mm a")}
//                     </dd>
//                     <dt className="w-1/3">Start Date</dt>
//                     <dd>{moment().format("DD MMM YYYY,h:mm a")}</dd>
//                     <dt className="w-1/3">Next Expiry Date</dt>
//                     <dd>
//                       {getcalculatedduedate(
//                         selectedPlan.time_unit,
//                         selectedPlan.unit_type
//                       )}
//                     </dd>
//                   </dl>
//                 </section>
//               </>
//             </div>
//           )}
//         </p>
//         <br />
//         <br />
//         {filterPlan && (
//           <Container fluid={true}>
//             <Row className="m-4">
//               <Input
//                 id="checkbox1"
//                 type="checkbox"
//                 onChange={handleClick}
//                 checked={walletamountcheckbox}
//                 name="use_wallet"
//               />
//               &nbsp;&nbsp;
//               <Label for="checkbox1" style={{ fontWeight: "bold" }}>
//                 Wallet Amount : &nbsp;&nbsp;₹{walletAmount}
//               </Label>
//             </Row>
//           </Container>
//         )}

//         {selectedPlan?.total_plan_cost && (
//           <>
//             <br />
//             <br />
//             <br /> <br />
//             <br />
//             <br /> <br />
//             <br />
//             <br />
//             <br />
//           </>
//         )}
//         <>
//           {staticIpBind && (
//             <span style={{ position: "relative", top: "30px" }}>
//               &nbsp; &nbsp; Static IP :
//               <div
//                 className={`franchise-switch ${staticipToggle}`}
//                 onClick={showStaticipToggle}
//               />
//             </span>
//           )}
//         </>
//         <div style={{ position: "relative", top: "30px" }}>
//           <StaticIp
//             ipPool={ipPool}
//             handleChange={handleChange}
//             renewPlan={changeplan}
//             staticIP={staticIP}
//             staticshow={staticshow}
//             staticIpBind={staticIpBind}
//             staticCost={staticipcost}
//             staticIPCost={staticIPCost}
//           />
//         </div>
//         <PaymentList
//           showPayment={showpayment}
//           submitdata={submitdata}
//           setShowPayment={setShowPayment}
//           setSelectedPaymentId={setSelectedPaymentId}
//         />

//         <div className="relative h-2/6 mt-10 mb-10 ml-4">
//           <div className="absolute bottom-0">
//             {selectedPlan?.total_plan_cost && getCalculations ? (
//               <Button
//                 className="primary-button"
//                 onClick={PaymentUpgardeSubmit}
//                 disabled={loaderSpinneer ? loaderSpinneer : loaderSpinneer}
//               >
//                 {loaderSpinneer ? (
//                   <SunspotLoader
//                     gradientColors={["#6366F1", "#E0E7FF"]}
//                     shadowColor={"#3730A3"}
//                     desktopSize={"10px"}
//                     mobileSize={"10px"}
//                   />
//                 ) : null}
//                 Change
//                 {/* {loadingPay && !isRetry ? " Payment Processing " : "Change"} */}
//               </Button>
//             ) : (
//               <Button
//                 className="primary-button"
//                 disabled={true}
//                 style={{ backgroundColor: "gray", cursor: "not-allowed" }}
//               >
//                 {"Change "}
//               </Button>
//             )}

//             <button className="primary-button ml-2" onClick={handleClose}>
//               Close
//             </button>
//           </div>
//         </div>
//       </div>
//       {/*  */}

//       <Modal isOpen={sucModal} toggle={successModal} centered>
//         <ModalHeader toggle={successModal}>
//           <div className="mb-4">Processing Request</div>
//           <hr></hr>
//         </ModalHeader>
//         <ModalBody>
//           <p className="mb-4">{alertMessage}</p>
//         </ModalBody>
//         <ModalFooter>
//           <hr></hr>
//           <Button className="primary-button mt-4">OK</Button>
//         </ModalFooter>
//       </Modal>

//       <PaymentStatusSuccessModal
//         paymentStatus={paymentStatus}
//         paymentStatusModal={paymentStatusModal}
//       />
//       <PaymentStatusPendingModal
//         paymentPending={paymentPending}
//         paymentPendingModal={paymentPendingModal}
//       />
//       <PaymentStatusFailUpgrade
//         paymentFailModal={paymentFailModal}
//         paymentFail={paymentFail}
//         PaymentUpgardeSubmit={PaymentUpgardeSubmit}
//       />
//     </React.Fragment>
//   );
// };

// export default SidePaneHOC(ChangePlan, "change");

// // import React, { useEffect, useState } from "react";
// // import SidePaneHOC from "./SidePaneHOC";
// // import { UpgradePlanTable } from "./UpgradePlanTable";
// // import PaymentList from "./paymentlist";
// // import {
// //   getUpgradePlaData,
// //   getAllPlansData,
// //   getPlanPayment,
// //   paymentAPIUpgrade,
// //   getPriorCheck,
// //   getBranchList,
// //   getAMountCalculation,
// // } from "../Axios";
// // import { CgClose } from "react-icons/cg";
// // import {
// //   Modal,
// //   ModalBody,
// //   ModalFooter,
// //   Button,
// //   ModalHeader,
// //   Input,
// //   Label,
// //   FormGroup,
// //   Container,
// //   Row,
// // } from "reactstrap";
// // import { Alert } from "../CommonComponents/Alert";
// // import { capitalizeString } from "../Utils/Constants";
// // import moment from "moment";
// // import { useHistory } from "react-router-dom";
// // import PaymentStatusSuccessModal from "./PaymentStatusSuccessmodal";
// // import PaymentStatusPendingModal from "./PaymentStatusPendingModal";
// // import PaymentStatusFailUpgrade from "./PaymentStatusFailupgrde";
// // import ReconnectingWebSocket from "reconnecting-websocket";
// // import StaticIp from "./StaticIP";
// // import UpgradeDays from "./UpgradeDays";
// // import { SunspotLoader } from "react-awesome-loaders";
// // import PlansTable from "./PlansTable";
// // import SelectedPlanForm from "./SelectedPlanForm";
// // import TableLoader from "./TableLoader";

// // const ChangePlan = (props) => {
// //   const areaid = props.areaId;
// //   const planID = props.planId;
// //   // staticIpBind
// //   const staticIpBind = props.static_ip_bind;
// //   const staticCost = props.static_ip_cost;
// //   const ipPoolId = props.ippool;
// //   const poolName = props.ippool_name;
// //   const radiusInfoId = props.radiusId;
// //   const planTimtUnit = props.plan_time_unit;
// //   const balnceAmount = props.balance_by_days;
// //   const balanceDays = props.balanace_by_monthly;
// //   const totalStatic = props.static_ip_total_cost;
// //   const staticCgst = props.static_ip_cgst;
// //   const statisSgst = props.static_ip_sgst;
// //   const walletAmount = props.wallet_info;
// //   // spinner
// //   const [loaderSpinneer, setLoaderSpinner] = useState(false);
// //   // toggle show for alreday static ip
// //   const [staticipToggle, setStaicIpToggle] = useState("on");
// //   const [staticshow, setStaticShow] = useState(staticIpBind ? true : false);
// //   function showStaticipToggle() {
// //     setStaicIpToggle(staticipToggle === "on" ? "off" : "on");
// //     setStaticShow(!staticshow);
// //   }
// //   const [gbfield, setGBfield] = useState(true);
// //   const GBWise = () => {
// //     setGBfield(true);
// //     setDaysfield(false);
// //   };
// //   const [daysfield, setDaysfield] = useState(false);
// //   const DayWise = () => {
// //     setDaysfield(true);
// //     setGBfield(false);
// //   };
// //   // pool list
// //   const [ipPool, setIpPool] = useState([]);
// //   // static ip's and plan cost
// //   const [staticIP, setStaticIP] = useState([]);
// //   const [staticIPCost, setStaticIPCost] = useState({});
// //   const [changeplan, setChangeplan] = useState({});
// //   const [paymentStatus, setPaymentStatus] = useState(false);
// //   const paymentStatusModal = () => {
// //     setPaymentStatus(!paymentStatus);
// //   };

// //   const [paymentPending, setPyamentpending] = useState(false);
// //   const paymentPendingModal = () => {
// //     setPyamentpending(!paymentPending);
// //   };
// //   const [paymentFail, setPyamentFail] = useState(false);
// //   const paymentFailModal = () => {
// //     setPyamentFail(!paymentPending);
// //   };
// //   const { customerId } = props;
// //   const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
// //   const { onClose, setAllOrPartialPlansFetch } = props;
// //   const allOrPartialPlans = "all";
// //   const [upgradePlansData, setUpgradePlansData] = useState([]);
// //   const [filterPlan, setFIlterPlan] = useState([]);
// //   const [selectedPlan, setSelectedPlan] = useState({});
// //   const [loading, setLoading] = useState(true);
// //   const [showAllPartial, setShowAllPartial] = useState(true);
// //   const [showpayment, setShowPayment] = useState(false);
// //   const [selectedPaymentId, setSelectedPaymentId] = useState(null);
// //   const [sucModal, setSucModal] = useState(false);
// //   const [alertMessage, setAlertMessage] = useState(null);
// //   const [loadingPay, setLoadingPay] = useState(false);
// //   const [showPaymentLinkSentModal, setPaymentLinkSentModal] = useState({
// //     message: "",
// //     uiStatus: false,
// //   });

// //   // wallet amount
// //   const [walletamountcheckbox, setWalletamountcheckbox] = useState(false);
// //   //handle click for check box
// //   const handleClick = (e) => {
// //     setWalletamountcheckbox(e.target.checked);
// //   };
// //   const [isRetry, showRetry] = useState(false);

// //   const history = useHistory();

// //   const handleClose = () => {
// //     onClose();
// //   };
// //   const handleSelectedPlanChange = (e, rowData) => {
// //     const selected = e.target.value;
// //     const selectedPlan = upgradePlansData.find((item) => item.id === selected);
// //     setSelectedPlan(rowData);
// //   };

// //   const handleSeeAllPlansClick = () => {
// //     setAllOrPartialPlansFetch("all");
// //   };

// //   const handleGreaterThanPlansClick = () => {
// //     setAllOrPartialPlansFetch("partial");
// //   };

// //   const handleChange = (e) => {
// //     setChangeplan((prev) => ({
// //       ...prev,
// //       [e.target.name]: e.target.value,
// //     }));
// //     if (e.target.name == "ippool") {
// //       getStaticIP(e.target.value);
// //     }
// //   };

// //   useEffect(() => {
// //     const id = props.planId;
// //     const areaid = props.areaId;
// //     (async function () {
// //       try {
// //         let response = null;
// //         if (allOrPartialPlans !== "all") {
// //           response = await getUpgradePlaData(`/accounts/area/${areaid}/plans`);
// //         } else {
// //           response = await getUpgradePlaData(
// //             `/accounts/loggedin/${areaid}/plans/${id}`
// //           );
// //         }
// //         const { data } = response;
// //         console.log(data);
// //         allOrPartialPlans === "all"
// //           ? setShowAllPartial(false)
// //           : setShowAllPartial(true);
// //         setUpgradePlansData(data);
// //         setFIlterPlan(response.data);
// //         setLoading(false);
// //       } catch (e) {
// //         console.log(e);
// //       }
// //     })();
// //   }, [allOrPartialPlans]);

// //   console.log("data===", upgradePlansData);

// //   const submitdata = () => {
// //     if (!!selectedPaymentId) {
// //       setSucModal(true);
// //       setAlertMessage("Your payment is processing...");
// //       setLoadingPay(true);
// //       const obj = {
// //         amount: selectedPlan.total_plan_cost,
// //         gst_calculated: "true",
// //         source: "IP",
// //         gateway_id: selectedPaymentId,
// //       };
// //       getPlanPayment("payment/", obj).then((response) => {
// //         console.log(response.data, "payment");
// //         setUpgradePlansData({});

// //         listenToWebsocket(response.data.payment_id);
// //       });
// //     } else {
// //       console.log("errors try again");
// //     }
// //   };

// //   const listenToWebsocket = (payment_id) => {
// //     let billingbaseurl =
// //       process.env.REACT_APP_API_URL_PAYMENT_PLAN.split("//")[1];
// //     let protocol = window.location.protocol ? "wss:" : "ws:";
// //     var ws = new ReconnectingWebSocket(
// //       `${protocol}//${billingbaseurl}/ws/${payment_id}/listen/payment/status`
// //     );
// //     ws.onopen = () => {
// //       console.log("socket cnxn successful");
// //     };
// //     ws.onclose = (e) => {
// //       console.log("socket closed", e);
// //     };
// //     ws.onmessage = (e) => {
// //       console.log(e.data);
// //       let responseData = JSON.parse(e.data);
// //       if (responseData.status == 1) {
// //         ws.close();
// //         setLoaderSpinner(false);
// //         setLoadingPay(false);
// //         paymentStatusModal(true);
// //       } else if (responseData.status == 2) {
// //         paymentPendingModal(true);
// //         setLoaderSpinner(true);
// //       } else if (responseData.status == 3) {
// //         paymentFailModal(true);
// //         setLoaderSpinner(true);
// //       } else {
// //         handleClose("invoice");
// //         showRetry(true);
// //       }
// //     };
// //   };

// //   const successModal = () => {
// //     if (sucModal) {
// //       setLoadingPay(false);
// //     }
// //     setSucModal(!sucModal);
// //   };

// //   // duew date

// //   const getcalculatedduedate = () => {
// //     const { startDate, time_unit, unit_type } = selectedPlan;
// //     let addUnitType = "days";
// //     switch (unit_type) {
// //       case "mon":
// //         addUnitType = "M";
// //         break;
// //       case "week":
// //         addUnitType = "W";
// //         break;
// //       case "day":
// //         addUnitType = "d";
// //         break;
// //       case "hour":
// //         addUnitType = "h";
// //         break;
// //       case "min":
// //         addUnitType = "m";
// //         break;
// //       default: {
// //         addUnitType = "h";
// //       }
// //     }

// //     const new_date = moment(startDate)
// //       .add(time_unit, addUnitType)
// //       .format("DD MMM YYYY,h:mm a");
// //     console.log(addUnitType);
// //     console.log(new_date);
// //     return new_date;
// //   };

// //   // checking ippool asigning or not
// //   const hideandSHowIPool = staticshow
// //     ? {
// //         plan: selectedPlan.id,
// //         area: areaid,
// //         ippool: Number(changeplan?.ippool)
// //           ? Number(changeplan?.ippool)
// //           : Number(ipPoolId),
// //       }
// //     : {
// //         plan: selectedPlan.id,
// //         area: areaid,
// //       };
// //   // calculating cost
// //   const totalStaticCost = parseFloat(
// //     parseFloat(
// //       staticIPCost?.cost_per_ip * selectedPlan.time_unit
// //         ? staticIPCost?.cost_per_ip * selectedPlan.time_unit
// //         : 0
// //     )
// //   );

// //   const finalAmount = parseFloat(selectedPlan.total_plan_cost).toFixed(2);
// //   const TotalGST =
// //     staticshow === true && selectedPlan?.plan_cgst === 0
// //       ? selectedPlan.total_plan_cost * 0.18
// //       : 0;
// //   const checkGST = staticIpBind && TotalGST;
// //   const staticipcost = staticshow
// //     ? (totalStatic / planTimtUnit) * selectedPlan?.time_unit
// //     : null;
// //   const totalAmount =
// //     Number(totalStaticCost) +
// //     Number(finalAmount) +
// //     Number(staticipcost) +
// //     Number(checkGST);

// //   // wallet priorcheck api call
// //   const PaymentUpgardeSubmit = () => {
// //     setLoaderSpinner(true);
// //     (async function (type) {
// //       try {
// //         const data = {
// //           plan: selectedPlan.id,
// //           area: areaid,
// //         };
// //         const objwithPool = hideandSHowIPool;
// //         const response = await getPriorCheck(
// //           `wallet/priorcheck`,
// //           Number(changeplan?.ippool) || ipPoolId ? objwithPool : data
// //         );
// //         if (response.data.check == true) {
// //           setLoaderSpinner(true);
// //           PaymentUpgardeplanSubmit();
// //         }
// //         if (response.data.check == false) {
// //           setLoaderSpinner(false);
// //         }
// //       } catch (e) {
// //         console.log(e);
// //       }
// //     })();
// //   };

// //   const withOutStatic = selectedPlan.time_unit * 212;
// //   // Radius Info
// //   const hideandSHowstaticIP = staticshow
// //     ? {
// //         id: radiusInfoId ? radiusInfoId : null,
// //         static_ip_bind: changeplan.static_ip_bind
// //           ? changeplan.static_ip_bind
// //           : staticIpBind,
// //         ippool_id: changeplan.ippool ? changeplan.ippool : ipPoolId,
// //       }
// //     : null;

// //   // amount calcualtion
// //   const [apiStatus, setApiStatus] = useState("idle");
// //   const [getCalculations, setGetCalculations] = useState();
// //   useEffect(() => {
// //     if (apiStatus === "pending") {
// //       setLoaderSpinner(true);
// //     } else {
// //       setLoaderSpinner(false);
// //     }
// //     (async function () {
// //       try {
// //         const obj = {
// //           service_plan: selectedPlan.id,
// //           balance: balnceAmount,
// //           use_wallet: walletamountcheckbox,
// //         };
// //         if (staticshow === true) {
// //           obj.radius_info = hideandSHowstaticIP;
// //         } else {
// //           delete obj.radius_info;
// //         }
// //         console.log(obj, "obj");
// //         if (selectedPlan?.id) {
// //           setLoaderSpinner(true);
// //           const response = await getAMountCalculation(
// //             `/customers/get/update/amount/${customerId}`,
// //             obj
// //           );
// //           const { data } = response;
// //           console.log(data);
// //           setGetCalculations(data);
// //           setApiStatus("success");
// //           setLoaderSpinner(false);
// //         }
// //       } catch (e) {
// //         console.log(e);
// //       }
// //     })();
// //   }, [selectedPlan, staticshow, walletamountcheckbox]);
// //   // upgarde api

// //   const PaymentUpgardeplanSubmit = () => {
// //     showRetry(false);
// //     (async function (type) {
// //       try {
// //         const data = {
// //           ...getCalculations,
// //           // plan: selectedPlan.id,
// //           // amount: parseFloat(totalAmount),
// //           // use_wallet: false,
// //           // radius_info: hideandSHowstaticIP,
// //           // balance: balnceAmount,
// //         };

// //         data.plan = selectedPlan.id;
// //         const response = await paymentAPIUpgrade(
// //           `/customers/enh/onl/plan/update/${customerId}`,
// //           data
// //         );
// //         if (response.data.route == true) {
// //           setLoaderSpinner(true);
// //           listenToWebsocket(response.data.payment_id);
// //           var win = window.open(`${response.data.next}`, "_blank");
// //           win && win?.focus();
// //         }
// //       } catch (e) {
// //         setLoaderSpinner(false);
// //         console.log(e);
// //         setPaymentLinkSentModal({
// //           message: "Something went wrong! Please try again.",
// //           uiStatus: true,
// //         });
// //       }
// //     })();
// //   };

// //   const totalcost = parseFloat(selectedPlan.total_plan_cost).toFixed(2);
// //   // branch list for getting ippools
// //   useEffect(() => {
// //     (async function () {
// //       try {
// //         const response = await getBranchList(`network/ippool/${areaid}/get`);
// //         setIpPool([...response.data]);
// //       } catch (e) {
// //         console.log(e);
// //       }
// //     })();
// //   }, []);

// //   // static ip
// //   const getStaticIP = (val) => {
// //     getBranchList(`network/ippool/used_ips/${val}`).then((res) => {
// //       let { available_ips } = res.data;
// //       setStaticIP([...available_ips]);
// //       setStaticIPCost(res.data);
// //     });
// //   };

// //   const [activeTab, setActiveTab] = useState(1);

// //   const tabs = ["OTT", "OTT + Fibernet"];

// //   const fibernetTableHeaders = [
// //     "Plan Name",
// //     "Plan Cost",
// //     "Plan Duration",
// //     "Plan Speed",
// //   ];

// //   return (
// //     <div className="sideout-container" style={{ flexDirection: "column" }}>
// //       <div className="sideout-container full-width">
// //         <div className="sideout-header">
// //           <p style={{ color: "black" }}>Upgrade Plan</p>
// //         </div>
// //         <div className="sideout-header with-close">
// //           <p style={{ color: "black" }}>Selected Plan Details</p>
// //           <CgClose
// //             onClick={() => handleClose("invoice")}
// //             className="yourCloseIconClass"
// //             // style={{
// //             //   height: iconHeight,
// //             //   width: iconWidth,
// //             //   cursor: "pointer",
// //             // }}
// //           />
// //         </div>
// //       </div>
// //       <div className="sideout-container  full-width">
// //         <div
// //           style={{
// //             width: "60%",
// //             borderRight: "1px solid rgba(216, 216, 216, 1)",
// //           }}
// //         >
// //           <div style={{ marginTop: "30px", marginLeft: "30px" }}>
// //             <input type="radio" id="gb" name="gb" value="gb" />
// //             <label for="gb" style={{ marginLeft: "10px" }}>
// //               GB
// //             </label>
// //             <input
// //               type="radio"
// //               id="days"
// //               name="days"
// //               value="days"
// //               style={{ marginLeft: "20px" }}
// //             />
// //             <label for="days" style={{ marginLeft: "10px" }}>
// //               Days
// //             </label>
// //           </div>
// //           <input
// //             className="package-searchbox"
// //             type="text"
// //             placeholder="Search with package name"
// //             name="packageName"
// //           />
// //           <div className="plan-tabs">
// //             <div
// //               className={`plan-tab ${activeTab === 1 ? "active-tab" : ""}`}
// //               onClick={() => setActiveTab(1)}
// //             >
// //               {tabs[0]}
// //             </div>
// //             <div
// //               className={`plan-tab ${activeTab === 2 ? "active-tab" : ""}`}
// //               onClick={() => setActiveTab(2)}
// //             >
// //               {tabs[1]}
// //             </div>
// //           </div>
// //           {loading ? (
// //             <TableLoader />
// //           ) : (
// //             <PlansTable
// //               headers={fibernetTableHeaders}
// //               data={upgradePlansData}
// //             />
// //           )}
// //           <div className="balance-container">
// //             <input type="checkbox" />
// //             <span style={{ marginLeft: "10px" }}>
// //               Wallet Amount : ₹ {walletAmount}
// //             </span>
// //           </div>
// //         </div>
// //         <div style={{ width: "40%" }}>
// //           <SelectedPlanForm plan={selectedPlan} onClose={handleClose} />
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default SidePaneHOC(ChangePlan, "change");

import React, { useEffect, useRef, useState } from "react";
import SidePaneHOC from "./SidePaneHOC";
import {
  getUpgradePlaData,
  getAllPlansData,
  getPlanPayment,
  paymentAPIUpgrade,
  getPriorCheck,
  getBranchList,
  getAMountCalculation,
  getOTTPlansList,
  getComboPlansList,
} from "../Axios";
import { CgClose } from "react-icons/cg";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader,
  Input,
  Label,
  FormGroup,
  Container,
  Row,
} from "reactstrap";
import { Alert } from "../CommonComponents/Alert";
import { capitalizeString } from "../Utils/Constants";
import moment from "moment";
import { useHistory } from "react-router-dom";
import PaymentStatusSuccessModal from "./PaymentStatusSuccessmodal";
import PaymentStatusPendingModal from "./PaymentStatusPendingModal";
import PaymentStatusFailUpgrade from "./PaymentStatusFailupgrde";
import ReconnectingWebSocket from "reconnecting-websocket";
import StaticIp from "./StaticIP";
import UpgradeDays from "./UpgradeDays";
import { SunspotLoader } from "react-awesome-loaders";
import PlansTable from "./PlansTable";
import SelectedPlanForm from "./SelectedPlanForm";
import TableLoader from "./TableLoader";

const headerStyle = {
  backgroundColor: "rgb(63 120 233 / 100%)",
  color: "white",
  position: "sticky",
  top: "0",
  zIndex: "999",
};

const iconFillColor = "#3f78e9";
const iconHeight = 25;
const iconWidth = 25;
const icondisplay = {
  display: "initial",
};

const ChangePlan = (props) => {
  const areaid = props.areaId;
  const planID = props.planId;
  // staticIpBind
  const staticIpBind = props.static_ip_bind;
  const staticCost = props.static_ip_cost;
  const ipPoolId = props.ippool;
  const poolName = props.ippool_name;
  const radiusInfoId = props.radiusId;
  const planTimtUnit = props.plan_time_unit;
  const balnceAmount = props.balance_by_days;
  const balanceDays = props.balanace_by_monthly;
  const totalStatic = props.static_ip_total_cost;
  const staticCgst = props.static_ip_cgst;
  const statisSgst = props.static_ip_sgst;
  const walletAmount = props.wallet_info;
  // spinner
  const [loaderSpinneer, setLoaderSpinner] = useState(false);
  // toggle show for alreday static ip
  const [staticipToggle, setStaicIpToggle] = useState("on");
  const [staticshow, setStaticShow] = useState(staticIpBind ? true : false);
  function showStaticipToggle() {
    setStaicIpToggle(staticipToggle === "on" ? "off" : "on");
    setStaticShow(!staticshow);
  }
  const [gbfield, setGBfield] = useState(true);
  const GBWise = () => {
    setGBfield(true);
    setDaysfield(false);
  };
  const [daysfield, setDaysfield] = useState(false);
  const DayWise = () => {
    setDaysfield(true);
    setGBfield(false);
  };
  // pool list
  const [ipPool, setIpPool] = useState([]);
  // static ip's and plan cost
  const [staticIP, setStaticIP] = useState([]);
  const [staticIPCost, setStaticIPCost] = useState({});
  const [changeplan, setChangeplan] = useState({});
  const [paymentStatus, setPaymentStatus] = useState(false);
  const paymentStatusModal = () => {
    setPaymentStatus(!paymentStatus);
  };

  const [paymentPending, setPyamentpending] = useState(false);
  const paymentPendingModal = () => {
    setPyamentpending(!paymentPending);
  };
  const [paymentFail, setPyamentFail] = useState(false);
  const paymentFailModal = () => {
    setPyamentFail(!paymentPending);
  };
  const { customerId } = props;
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const { onClose, setAllOrPartialPlansFetch } = props;
  const allOrPartialPlans = "all";
  const [upgradePlansData, setUpgradePlansData] = useState([]);
  const [comboPlansData, setComboPlansData] = useState([]);
  const [ottPlansData, setOttPlansData] = useState([]);
  const [filterPlan, setFIlterPlan] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const [showAllPartial, setShowAllPartial] = useState(true);
  const [showpayment, setShowPayment] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState(null);
  const [sucModal, setSucModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [loadingPay, setLoadingPay] = useState(false);
  const [showPaymentLinkSentModal, setPaymentLinkSentModal] = useState({
    message: "",
    uiStatus: false,
  });
  const fibernetPlansRef = useRef();
  const ottPlansRef = useRef();
  const comboPlansRef = useRef();

  // wallet amount
  const [walletamountcheckbox, setWalletamountcheckbox] = useState(false);
  //handle click for check box
  const handleClick = (e) => {
    setWalletamountcheckbox(e.target.checked);
  };
  const [isRetry, showRetry] = useState(false);

  const history = useHistory();

  const handleClose = () => {
    onClose();
  };
  const handleSelectedPlanChange = (e, rowData) => {
    const selected = e.target.value;
    const selectedPlan = upgradePlansData.find((item) => item.id === selected);
    setSelectedPlan(rowData);
  };

  const handleSeeAllPlansClick = () => {
    setAllOrPartialPlansFetch("all");
  };

  const handleGreaterThanPlansClick = () => {
    setAllOrPartialPlansFetch("partial");
  };

  const handleChange = (e) => {
    setChangeplan((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    if (e.target.name == "ippool") {
      getStaticIP(e.target.value);
    }
  };

  useEffect(() => {
    const id = props.planId;
    const areaid = props.areaId;
    (async function () {
      try {
        let response = null;
        if (allOrPartialPlans !== "all") {
          response = await getUpgradePlaData(`/accounts/area/${areaid}/plans`);
        } else {
          response = await getUpgradePlaData(
            `/accounts/loggedin/${areaid}/plans/${id}`
          );
        }
        const { data } = response;
        console.log(data);
        allOrPartialPlans === "all"
          ? setShowAllPartial(false)
          : setShowAllPartial(true);
        const res = data.map((row, id) => ({
          ...row,
          id,
        }));
        setUpgradePlansData(res);
        fibernetPlansRef.current = res;
        setFIlterPlan(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [allOrPartialPlans]);

  useEffect(() => {
    if (!ottPlansData?.length)
      (async function () {
        try {
          const {
            data: {
              data: { result },
            },
          } = await getOTTPlansList();
          const res = result.map((row, id) => ({
            ...row,
            id,
          }));
          ottPlansRef.current = res;
          setOttPlansData(res);
        } catch (e) {
          console.log(e);
        }
      })();
  }, []);

  useEffect(() => {
    if (!comboPlansData?.length)
      (async function () {
        try {
          const {
            data: {
              data: { results },
            },
          } = await getComboPlansList();
          const res = results.map((row, id) => ({
            ...row,
            id,
          }));
          comboPlansRef.current = res;
          setComboPlansData(res);
        } catch (e) {
          console.log(e);
        }
      })();
  }, []);

  const onSelectFibernetPlan = (plan) => {
    setSelectedPlan({ type: "fibernet", plan });
    setUpgradePlansData((prev) =>
      prev.map((row) => ({
        ...row,
        isSelected: row.id === plan.id,
      }))
    );
    setOttPlansData((prev) =>
      prev.map((row) => ({ ...row, isSelected: false }))
    );
    setComboPlansData((prev) =>
      prev.map((row) => ({ ...row, isSelected: false }))
    );
  };

  const onSelectOttPlan = (plan) => {
    setSelectedPlan({ type: "ott", plan });
    setUpgradePlansData((prev) =>
      prev.map((row) => ({ ...row, isSelected: false }))
    );
    setOttPlansData((prev) =>
      prev.map((row) => ({
        ...row,
        isSelected: row.id === plan.id,
      }))
    );
    setComboPlansData((prev) =>
      prev.map((row) => ({ ...row, isSelected: false }))
    );
  };

  const onSelectComboPlan = (plan) => {
    setSelectedPlan({ type: "combo", plan });
    setUpgradePlansData((prev) =>
      prev.map((row) => ({ ...row, isSelected: false }))
    );
    setOttPlansData((prev) =>
      prev.map((row) => ({ ...row, isSelected: false }))
    );
    setComboPlansData((prev) =>
      prev.map((row) => ({
        ...row,
        isSelected: row.id === plan.id,
      }))
    );
  };

  // console.log("data===", upgradePlansData);

  const submitdata = () => {
    if (!!selectedPaymentId) {
      setSucModal(true);
      setAlertMessage("Your payment is processing...");
      setLoadingPay(true);
      const obj = {
        amount: selectedPlan.total_plan_cost,
        gst_calculated: "true",
        source: "IP",
        gateway_id: selectedPaymentId,
      };
      getPlanPayment("payment/", obj).then((response) => {
        console.log(response.data, "payment");
        setUpgradePlansData({});

        listenToWebsocket(response.data.payment_id);
      });
    } else {
      console.log("errors try again");
    }
  };

  const listenToWebsocket = (payment_id) => {
    let billingbaseurl =
      process.env.REACT_APP_API_URL_PAYMENT_PLAN.split("//")[1];
    let protocol = window.location.protocol ? "wss:" : "ws:";
    var ws = new ReconnectingWebSocket(
      `${protocol}//${billingbaseurl}/ws/${payment_id}/listen/payment/status`
    );
    ws.onopen = () => {
      console.log("socket cnxn successful");
    };
    ws.onclose = (e) => {
      console.log("socket closed", e);
    };
    ws.onmessage = (e) => {
      console.log(e.data);
      let responseData = JSON.parse(e.data);
      if (responseData.status == 1) {
        ws.close();
        setLoaderSpinner(false);
        setLoadingPay(false);
        paymentStatusModal(true);
      } else if (responseData.status == 2) {
        paymentPendingModal(true);
        setLoaderSpinner(true);
      } else if (responseData.status == 3) {
        paymentFailModal(true);
        setLoaderSpinner(true);
      } else {
        handleClose("invoice");
        showRetry(true);
      }
    };
  };

  const successModal = () => {
    if (sucModal) {
      setLoadingPay(false);
    }
    setSucModal(!sucModal);
  };

  // duew date

  const getcalculatedduedate = () => {
    const { startDate, time_unit, unit_type } = selectedPlan;
    let addUnitType = "days";
    switch (unit_type) {
      case "mon":
        addUnitType = "M";
        break;
      case "week":
        addUnitType = "W";
        break;
      case "day":
        addUnitType = "d";
        break;
      case "hour":
        addUnitType = "h";
        break;
      case "min":
        addUnitType = "m";
        break;
      default: {
        addUnitType = "h";
      }
    }

    const new_date = moment(startDate)
      .add(time_unit, addUnitType)
      .format("DD MMM YYYY,h:mm a");
    console.log(addUnitType);
    console.log(new_date);
    return new_date;
  };

  // checking ippool asigning or not
  const hideandSHowIPool = staticshow
    ? {
        plan: selectedPlan.id,
        area: areaid,
        ippool: Number(changeplan?.ippool)
          ? Number(changeplan?.ippool)
          : Number(ipPoolId),
      }
    : {
        plan: selectedPlan.id,
        area: areaid,
      };
  // calculating cost
  const totalStaticCost = parseFloat(
    parseFloat(
      staticIPCost?.cost_per_ip * selectedPlan.time_unit
        ? staticIPCost?.cost_per_ip * selectedPlan.time_unit
        : 0
    )
  );

  const finalAmount = parseFloat(selectedPlan.total_plan_cost).toFixed(2);
  const TotalGST =
    staticshow === true && selectedPlan?.plan_cgst === 0
      ? selectedPlan.total_plan_cost * 0.18
      : 0;
  const checkGST = staticIpBind && TotalGST;
  const staticipcost = staticshow
    ? (totalStatic / planTimtUnit) * selectedPlan?.time_unit
    : null;
  const totalAmount =
    Number(totalStaticCost) +
    Number(finalAmount) +
    Number(staticipcost) +
    Number(checkGST);

  // wallet priorcheck api call
  const PaymentUpgardeSubmit = () => {
    setLoaderSpinner(true);
    (async function (type) {
      try {
        const data = {
          plan: selectedPlan.id,
          area: areaid,
        };
        const objwithPool = hideandSHowIPool;
        const response = await getPriorCheck(
          `wallet/priorcheck`,
          Number(changeplan?.ippool) || ipPoolId ? objwithPool : data
        );
        if (response.data.check == true) {
          setLoaderSpinner(true);
          PaymentUpgardeplanSubmit();
        }
        if (response.data.check == false) {
          setLoaderSpinner(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  };

  const withOutStatic = selectedPlan.time_unit * 212;
  // Radius Info
  const hideandSHowstaticIP = staticshow
    ? {
        id: radiusInfoId ? radiusInfoId : null,
        static_ip_bind: changeplan.static_ip_bind
          ? changeplan.static_ip_bind
          : staticIpBind,
        ippool_id: changeplan.ippool ? changeplan.ippool : ipPoolId,
      }
    : null;

  // amount calcualtion
  const [apiStatus, setApiStatus] = useState("idle");
  const [getCalculations, setGetCalculations] = useState();
  useEffect(() => {
    if (apiStatus === "pending") {
      setLoaderSpinner(true);
    } else {
      setLoaderSpinner(false);
    }
    (async function () {
      try {
        const obj = {
          service_plan: selectedPlan.id,
          balance: balnceAmount,
          use_wallet: walletamountcheckbox,
        };
        if (staticshow === true) {
          obj.radius_info = hideandSHowstaticIP;
        } else {
          delete obj.radius_info;
        }
        console.log(obj, "obj");
        if (selectedPlan?.id) {
          setLoaderSpinner(true);
          const response = await getAMountCalculation(
            `/customers/get/update/amount/${customerId}`,
            obj
          );
          const { data } = response;
          console.log(data);
          setGetCalculations(data);
          setApiStatus("success");
          setLoaderSpinner(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [selectedPlan, staticshow, walletamountcheckbox]);
  // upgarde api

  const PaymentUpgardeplanSubmit = () => {
    showRetry(false);
    (async function (type) {
      try {
        const data = {
          ...getCalculations,
          // plan: selectedPlan.id,
          // amount: parseFloat(totalAmount),
          // use_wallet: false,
          // radius_info: hideandSHowstaticIP,
          // balance: balnceAmount,
        };

        data.plan = selectedPlan.id;
        const response = await paymentAPIUpgrade(
          `/customers/enh/onl/plan/update/${customerId}`,
          data
        );
        if (response.data.route == true) {
          setLoaderSpinner(true);
          listenToWebsocket(response.data.payment_id);
          var win = window.open(`${response.data.next}`, "_blank");
          win && win?.focus();
        }
      } catch (e) {
        setLoaderSpinner(false);
        console.log(e);
        setPaymentLinkSentModal({
          message: "Something went wrong! Please try again.",
          uiStatus: true,
        });
      }
    })();
  };

  const totalcost = parseFloat(selectedPlan.total_plan_cost).toFixed(2);
  // branch list for getting ippools
  useEffect(() => {
    (async function () {
      try {
        const response = await getBranchList(`network/ippool/${areaid}/get`);
        setIpPool([...response.data]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  // static ip
  const getStaticIP = (val) => {
    getBranchList(`network/ippool/used_ips/${val}`).then((res) => {
      let { available_ips } = res.data;
      setStaticIP([...available_ips]);
      setStaticIPCost(res.data);
    });
  };

  const [activeTab, setActiveTab] = useState(1);
  const [searchText, setSearchText] = useState("");

  const tabs = ["Fibernet", "OTT", "Fibernet + OTT"];

  const fibernetTableHeaders = [
    "Plan Name",
    "Plan Cost",
    "Plan Duration",
    "Plan Speed",
  ];

  const ottTableHeaders = [
    "Plan Name",
    "Plan Cost",
    "Plan Duration",
    "OTT List",
  ];

  const comoboTableHeaders = [
    "Plan Name",
    "Plan Cost",
    "Plan Duration",
    "Plan Speed",
    "OTT List",
  ];

  useEffect(() => {
    if (searchText.length === 0) {
      setUpgradePlansData(
        (fibernetPlansRef.current || []).map((plan) => ({
          ...plan,
          isSelected:
            selectedPlan?.type === "fibernet" &&
            plan.id === selectedPlan?.plan?.id,
        }))
      );
      setOttPlansData(
        (ottPlansRef.current || []).map((plan) => ({
          ...plan,
          isSelected:
            selectedPlan?.type === "ott" && plan.id === selectedPlan?.plan?.id,
        }))
      );
      setComboPlansData(
        (comboPlansRef.current || []).map((plan) => ({
          ...plan,
          isSelected:
            selectedPlan?.type === "combo" &&
            plan.id === selectedPlan?.plan?.id,
        }))
      );
    }
  }, [searchText]);

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    if (activeTab === 1) {
      setUpgradePlansData((plans) =>
        plans.filter((plan) =>
          plan.package_name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else if (activeTab === 2) {
      setOttPlansData((plans) =>
        plans.filter((plan) =>
          plan.plan_name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else {
      setComboPlansData((plans) =>
        plans.filter((plan) =>
          plan.combo_plan_name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };

  return (
    <div className="sideout-container" style={{ flexDirection: "column" }}>
      <div className="sideout-container full-width">
        <div className="sideout-header">
          <p style={{ color: "black" }}>Change Plan</p>
        </div>
        <div className="sideout-header with-close">
          <p style={{ color: "black" }}>Selected Plan Details</p>
          <CgClose
            onClick={() => handleClose("invoice")}
            className="yourCloseIconClass"
          />
        </div>
      </div>
      <div className="sideout-container  full-width">
        <div
          style={{
            width: "60%",
            borderRight: "1px solid rgba(216, 216, 216, 1)",
          }}
        >
          <form onSubmit={handleSubmitSearch}>
            <input
              className="package-searchbox"
              type="text"
              placeholder="Search with package name"
              name="packageName"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </form>

          <div className="plan-tabs">
            <div
              className={`plan-tab ${activeTab === 1 ? "active-tab" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              {tabs[0]}
            </div>
            <div
              className={`plan-tab ${activeTab === 2 ? "active-tab" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              {tabs[1]}
            </div>
            <div
              className={`plan-tab ${activeTab === 3 ? "active-tab" : ""}`}
              onClick={() => setActiveTab(3)}
            >
              {tabs[2]}
            </div>
          </div>
          {loading ? (
            <TableLoader />
          ) : (
            <PlansTable
              fibernetTableHeaders={fibernetTableHeaders}
              ottTableHeaders={ottTableHeaders}
              comoboTableHeaders={comoboTableHeaders}
              fibernetPlansData={upgradePlansData}
              comboPlansData={comboPlansData}
              ottPlansData={ottPlansData}
              activeTab={activeTab}
              onSelectFibernetPlan={onSelectFibernetPlan}
              onSelectOttPlan={onSelectOttPlan}
              onSelectComboPlan={onSelectComboPlan}
            />
          )}
          <div className="balance-container">
            <input type="checkbox" />
            <span style={{ marginLeft: "10px" }}>
              Wallet Amount : ₹ {walletAmount}
            </span>
          </div>
        </div>
        <div style={{ width: "40%" }}>
          <SelectedPlanForm
            selectedPlanObject={selectedPlan}
            onClose={handleClose}
            action="Change Plan"
          />
        </div>
      </div>
    </div>
  );
};

export default SidePaneHOC(ChangePlan, "change");
