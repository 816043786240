import "./App.css";
import AppNavigator from "./AppNavigator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

function App() {
  return (
    <>
      <ToastContainer />
      <AppNavigator />
    </>
  );
}

export default App;
