import React, { useEffect, useState, useRef } from "react";
import OtpInput from "react-otp-input";
import { loginWithOTP } from "../Axios/";
import { useHistory } from "react-router";
import "./CommonComponents.style.css";
export const LoginWithOTP = (props) => {
  const history = useHistory();
  const [otp, setOTP] = useState("");
  const [hasError, setError] = useState(false);
  const [counter, setCounter] = useState(30);
  const [isWrongCredentials, setWrongCredentials] = useState(false);
  const timerID = useRef(null);

  const handleOTPChange = (otp) => {
    setOTP(otp);
  };

  const handleVerifyAndSubmit = async () => {
    const OTPString = otp + "";
    if (OTPString.split("").length < 6) {
      setError(true);
    } else {
      clearTimer();
      const userdata = { username: props.userName, otp };
      try {
        const response = await loginWithOTP("/accounts/login", userdata);
        const { data } = response;
        console.log(data);
        if (
          data &&
          data.detail === "No active account found with the given credentials"
        ) {
          setWrongCredentials(true);
        } else {
          localStorage.setItem("token", JSON.stringify(data));
          const redirectURL = `${process.env.PUBLIC_URL}/dashboard`;
          history.push(redirectURL);
        }
      } catch (e) {
        setWrongCredentials(true);
      }
    }
  };

  const decrementCounter = () => {
    if (counter === 0) {
      setCounter(30);
      clearTimer();
    } else setCounter((prevCounter) => prevCounter - 1);
  };

  const clearTimer = () => {
    setCounter(30);
    clearInterval(timerID.current);
    timerID.current = null;
  };

  useEffect(() => {
    timerID.current = setInterval(() => decrementCounter(), 1000);
  }, []);

  const handleResendClick = () => {
    props.handleSendOTPClick();
    console.log("Resend OTP");
    clearTimer();
    timerID.current = setInterval(() => decrementCounter(), 1000);
  };

  return (
    <React.Fragment>
      <OtpInput
        value={otp}
        onChange={handleOTPChange}
        numInputs={6}
        separator={<>{`-`}</>}
        containerStyle={""}
        inputStyle={"otp-input"}
        focusStyle={""}
        isInputNum={true}
        hasErrored={true}
      />
      <span className="mt-10 mb-5 text-base text-center">
        Did not receive code?
        <button className="font-bold ml-2 link" onClick={handleResendClick}>
          Resend again
        </button>
      </span>
      <button
        className="block mb-3 secondary-button w-60 p-1"
        onClick={handleVerifyAndSubmit}
      >
        Verify and Sign in
      </button>
    </React.Fragment>
  );
};
