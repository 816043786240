import React, { useEffect, useState } from "react";
import OttIcon from "../Assets/ott-icon.png";

const SelectedPlanForm = ({
  selectedPlanObject,
  onClose,
  action = "Upgrade",
}) => {
  const [planDetails, setPlanDetails] = useState({});
  const { type, plan } = selectedPlanObject;

  useEffect(() => {
    if (type === "fibernet") {
      setPlanDetails({
        plan_name: plan.package_name,
        validity: plan.time_unit + " " + plan.unit_type + "(s)",
        speed: plan.download_speed,
        plan_cost: plan.total_plan_cost,
      });
    } else if (type === "ott") {
      setPlanDetails({
        plan_name: plan.plan_name,
        validity: `${plan.plan_duration}month(s)`,
        plan_cost: plan.total,
        ott_services: plan.ott_services,
      });
    } else if (type === "combo") {
      setPlanDetails({
        plan_name: plan.combo_plan_name,
        validity: `${plan.plan_duration}month(s)`,
        plan_cost: plan.total,
        speed: plan.internet_speed,
        ott_services: plan.ott_services,
      });
    } else {
      setPlanDetails();
    }
  }, [type, plan]);

  return planDetails ? (
    <form
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "40px",
      }}
    >
      {Object.keys(planDetails || {}).map((field) =>
        getInputField(field, planDetails)
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "100px",
        }}
      >
        <div
          className="register-button"
          style={{
            borderRadius: "3px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
        >
          {action}
        </div>
        <div
          className="register-button"
          style={{
            marginLeft: "20px",
            borderRadius: "3px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
          onClick={onClose}
        >
          Close
        </div>
      </div>
    </form>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1>Select a plan</h1>
    </div>
  );
};

const fieldNames = {
  plan_name: "Plan Name",
  validity: "Plan Validity",
  speed: "Plan Speed",
  plan_cost: "Plan Cost",
};

const getInputField = (type, planDetails) => {
  if (type === "ott_services") {
    return (
      <div style={{ marginTop: 30 }}>
        <div>Ott Services</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 10,
          }}
        >
          {planDetails[type].map((ott, idx) => (
            <div
              key={idx}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
                marginRight: 20,
              }}
            >
              <img
                style={{
                  objectFit: "contain",
                  height: "35px",
                  width: "35px",
                }}
                src={OttIcon}
                alt="ott-icon"
              />
              {ott}
            </div>
          ))}
        </div>
      </div>
    );
  }
  return (
    <div className="selected-plan-input-container">
      <p>{fieldNames[type]}</p>
      <input
        className="selected-plan-input"
        type="text"
        name={type}
        value={`${type === "plan_cost" ? "₹" : ""} ${planDetails[type]}`}
        disabled
      />
    </div>
  );
};

export default SelectedPlanForm;
