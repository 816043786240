import React, { useEffect, useState } from "react";

const RenewPlanForm = ({ plan, onClose, action = "Upgrade" }) => {
  const [planDetails, setPlanDetails] = useState({});

  const handleChange = (e) => {
    setPlanDetails({
      ...planDetails,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setPlanDetails({
      plan_name: plan.package_name,
      validity: plan.time_unit + " " + plan.unit_type + "(s)",
      upload_speed: plan.upload_speed,
      download_speed: plan.download_speed,
      plan_cost: `₹ ${parseFloat(plan.total_plan_cost).toFixed(2)}`,
      total_amount: "₹ 118.00",
      current_expiry_date: "12 Mar 2024, 2:15Pm",
      start_date: "15 Mar 2024, 2:15Pm",
      new_expiry_date: "27 Mar 2024, 2:15Pm",
    });
  }, [plan]);

  return (
    <form
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "40px",
      }}
    >
      <div className="selected-plan-input-container">
        <p>Plan Name</p>
        <input
          className="selected-plan-input"
          type="text"
          name="plan_name"
          value={planDetails.plan_name}
          onChange={handleChange}
        />
      </div>
      <div className="selected-plan-input-container">
        <p>Validity</p>
        <input
          className="selected-plan-input"
          type="text"
          name="validity"
          value={planDetails.validity}
          onChange={handleChange}
        />
      </div>
      <div className="selected-plan-input-container">
        <p>Upload Speed</p>
        <input
          className="selected-plan-input"
          type="text"
          name="upload_speed"
          value={planDetails.upload_speed}
          onChange={handleChange}
        />
      </div>
      <div className="selected-plan-input-container">
        <p>Download Speed</p>
        <input
          className="selected-plan-input"
          type="text"
          name="download_speed"
          value={planDetails.download_speed}
          onChange={handleChange}
        />
      </div>
      <div className="selected-plan-input-container">
        <p>Plan Cost</p>
        <input
          className="selected-plan-input"
          type="text"
          name="plan_cost"
          value={planDetails.plan_cost}
          onChange={handleChange}
        />
      </div>
      <div className="selected-plan-input-container">
        <p>Total amount to be paid</p>
        <input
          className="selected-plan-input"
          type="text"
          name="total_amount"
          value={planDetails.total_amount}
          onChange={handleChange}
        />
      </div>
      <div className="selected-plan-input-container">
        <p>Current Expiry Date</p>
        <input
          className="selected-plan-input"
          type="text"
          name="current_expiry_date"
          value={planDetails.current_expiry_date}
          onChange={handleChange}
        />
      </div>
      <div className="selected-plan-input-container">
        <p>Start Date</p>
        <input
          className="selected-plan-input"
          type="text"
          name="start_date"
          value={planDetails.start_date}
          onChange={handleChange}
        />
      </div>
      <div className="selected-plan-input-container">
        <p>New Expiry Date</p>
        <input
          className="selected-plan-input"
          type="text"
          name="new_expiry_date"
          value={planDetails.new_expiry_date}
          onChange={handleChange}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "100px",
        }}
      >
        <div
          className="register-button"
          style={{
            borderRadius: "3px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
        >
          {action}
        </div>
        <div
          className="register-button"
          style={{
            marginLeft: "20px",
            borderRadius: "3px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
          onClick={onClose}
        >
          Close
        </div>
      </div>
    </form>
  );
};

export default RenewPlanForm;
