import React from "react";
import { SunspotLoader } from "react-awesome-loaders";

const TableLoader = () => {
  return (
    <>
      <div className="flex w-full h-full justify-center items-center p-10">
        <SunspotLoader
          gradientColors={["#6366F1", "#E0E7FF"]}
          shadowColor={"#3730A3"}
          desktopSize={"60px"}
          mobileSize={"40px"}
        />
      </div>
    </>
  );
};

export default TableLoader;
